import React from 'react'
import ptesla from '../assets/projects/p-tesla.jpg';
import pesewa from '../assets/projects/p-esewa.jpg';
import pdisney from '../assets/projects/p-disney.jpg';
import pcontact from '../assets/projects/p-contact.jpg';
import pkbc from '../assets/projects/p-kbc.jpg';
import preact from '../assets/projects/p-react.jpg';
import { AiFillGithub } from 'react-icons/ai'

export default function Projects() {
    return (
        <div className='projects'>
            <div className="heading">Projects</div>
            <p className="text">Check out some of my recent work</p>
            <div className="projects-box">
                <div className="projects-card">
                    <a className='gitIcon' href="https://github.com/Avilekh600/teslaclone" target='_blank'>< AiFillGithub /></a>
                    <img src={ptesla} alt="tesla" />
                    <div className="title">Tesla Clone  <a href='https://avilekh-tesla-clone.netlify.app/' target='_blank' className="preview text">Preview</a> </div>

                    <pre className="text" >#react #css</pre>
                </div>
                <div className="projects-card">
                    <a className='gitIcon' href="https://github.com/Avilekh600/eSewaClone" target='_blank'>< AiFillGithub /></a>
                    <img src={pesewa} alt="esewa" />
                    <div className="title">eSewa Clone  <a href='https://avilekh-esewa.netlify.app/' target='_blank' className="preview text">Preview</a> </div>

                    <pre className="text" >#react #css</pre>
                </div>
                <div className="projects-card">
                    <a className='gitIcon' href="https://github.com/Avilekh600/disney" target='_blank'>< AiFillGithub /></a>
                    <img src={pdisney} alt="disney" />
                    <div className="title">disney Clone  <a href='https://avilekh-disney.netlify.app/' target='_blank' className="preview text">Preview</a> </div>

                    <pre className="text" >#react #css</pre>
                </div>
                <div className="projects-card">
                    <a className='gitIcon' href="https://github.com/Avilekh600/KBC" target='_blank'>< AiFillGithub /></a>
                    <img src={pkbc} alt="kbc" />
                    <div className="title">kbc Game  <a href='https://avilekh-kbc.netlify.app/' target='_blank' className="preview text">Preview</a> </div>

                    <pre className="text" >#react #css</pre>
                </div>
                <div className="projects-card">
                    <a className='gitIcon' href="https://github.com/Avilekh600/React" target='_blank'>< AiFillGithub /></a>
                    <img src={preact} alt="react" />
                    <div className="title">React clone  <a href='https://avilekh600.github.io/React/' target='_blank' className="preview text">Preview</a> </div>

                    <pre className="text" >#tailwind css #html</pre>
                </div>
                <div className="projects-card">
                    <a className='gitIcon' href="https://github.com/Avilekh600/contact" target='_blank'>< AiFillGithub /></a>
                    <img src={pcontact} alt="contact" />
                    <div className="title">Contact us<a href='https://avilekh-contact.netlify.app/' target='_blank' className="preview text">Preview</a> </div>

                    <pre className="text" >#react #firebase</pre>
                </div>

            </div>
        </div>
    )
}
