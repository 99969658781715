import React, { useState } from 'react';
import logo from  '../assets/avilekh-yellow.png'
import { Link } from 'react-scroll';
import { GiHamburgerMenu } from 'react-icons/gi'
import {RxCross2 } from 'react-icons/rx'


export default function Header() {
    const [click,setClick] = useState(false);
    const handleClick = () => setClick(!click)
  return (
    <div className='header' >
        <div className="left">
        <Link to="home" spy={true} smooth={true} offset={50} duration={500} >  <img src={logo} alt="logo" />
                      </Link>
           
        </div>
        <div className="right">
            <ul >
            <li>  <Link to="home" spy={true} smooth={true} offset={-50} duration={500} onClick={handleClick}  > Home
                      </Link>
               </li>
               <li >  <Link to="skills" spy={true} smooth={true} offset={-50} duration={500} onClick={handleClick}> Skills
                      </Link>
               </li>
               <li>  <Link to="projects" spy={true} smooth={true} offset={-50} duration={500}  onClick={handleClick}> Projects
                      </Link>
               </li>
                <li>  <Link to="contactus" spy={true} smooth={true} offset={-50} duration={500}  onClick={handleClick}> Contact us
                      </Link>
               </li>
               
            </ul>
        </div>
        <div className="hamburger">

             <div className="hamicons" onClick={handleClick}><GiHamburgerMenu/></div>
        <div className={ click? 'true-burger-right burger-right right' : "burger-right right"}>
            
            <div className="hamicon-cross" onClick={handleClick}><RxCross2 className='font'/></div>
            <ul >
                <li>  <Link to="home" spy={true} smooth={true} offset={-50} duration={500} onClick={handleClick}  > Home
                      </Link>
               </li>
               <li >  <Link to="skills" spy={true} smooth={true} offset={-50} duration={500} onClick={handleClick}> Skills
                      </Link>
               </li>
               <li>  <Link to="projects" spy={true} smooth={true} offset={-50} duration={500}  onClick={handleClick}> Projects
                      </Link>
               </li>
                <li>  <Link to="contactus" spy={true} smooth={true} offset={-50} duration={500}  onClick={handleClick}> Contact us
                      </Link>
               </li>
              
              
               
            </ul>
            
        </div>
        </div>
    </div>
  )
}


