import React from 'react'

export default function Contact() {
  return (
    <div className='contactus'>
        <div className="heading">Contact me</div>
        <p className="text">submit a form below to contact me</p>
        <form className='formdiv' action="https://getform.io/f/d943860f-6afd-4332-9afa-ce30d53b0a15" method='POST' autoComplete='off'>
            <div className="form">
                <div className="inputdiv">
                        <span>Your Name*</span><br /><input type="text" name='name' className='input text' placeholder='Enter your name' required/>
                </div>
                <div className="inputdiv">
                    <span>Your Email*</span><br /><input type="email" name='email' className='input text' placeholder='Enter your email' required/>
                </div>    
            </div>
            <div className="form">
                <div className="inputdiv">
                      <span>Your Phone</span><br /> <input type="number" name="phone" placeholder='Enter your mobile number' className='input text' />
                </div>
                 <div className="inputdiv">
                     <span>Address</span><br /> <input type="text" name="phone" placeholder='Enter your address' className='input text' />
                 </div>
                 
            </div>
            <div className="inputdiv">
                <textarea className=' text' name="message" placeholder='message here...' cols="30" rows="10"></textarea>
            </div>
            <button type='submit' className="submit ">submit</button>
        </form>
    </div>
  )
}
