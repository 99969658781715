import React from 'react'
import html from '../assets/html.png'
import css from '../assets/css.png'
import js from '../assets/javascript.png'
import tailwind from '../assets/tailwind.png'
import react from '../assets/react.png'
import github from '../assets/github.png'



export default function Skills() {
  return (
    <div className='skills'>
        <div className="heading ">Experiences</div>
        <p className='text'>These are the technology I've worked with</p>
        <div className="tech">
            <div className="tech1 techG"><img src={html} alt="html" /> <p>HTML</p></div>
            <div className="tech3 techG"><img src={js} alt="js" /><p>JAVASCRIPT</p></div>
            <div className="tech4 techG"><img src={tailwind} alt="tailwind" /><p>TAILWIND</p></div>
            <div className="tech2 techG"><img src={css} alt="css" /> <p>CSS</p></div>
            <div className="tech5 techG"><img src={react} alt="react" /><p>REACT</p></div>
            <div className="tech6 techG"><img src={github} alt="github" /><p>GITHUB</p></div>
        </div>
    </div>
  )
}
