import React from 'react'
import styled from 'styled-components'
import { AiFillGithub } from 'react-icons/ai'

export default function Home() {
  return (
    <Box className='home hero'>
         <div className="text-field">
         <div className="intro">Hi, I'm <span>Avilekh</span></div>
         <div className="text">A passionate React developer from Nepal <br />  Currently Learning MERN STACK </div>
         <div className="button"><a href="https://github.com/Avilekh600?tab=repositories" target='_blank'>< AiFillGithub/> GitHub</a></div>
         </div>
    </Box>
  )
}

const Box = styled.div`
          color: greenyellow;
          min-height : 100vh;
          padding-top : 60px;   
`;